import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Container from '../components/container';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Headline from '../components/headline';
import { Row, Column } from '../components/grid';

const Objetivos = ({ data }) => (
  <Layout>
    <SEO
      title="Objetivos"
      description="Mejorar la situación fisica, psicologica, emocional, social y economica
        de niños y jovenes que padecen cualquier enfermedad cronico degenerativa
        y de sus familias."
    />
    <Container>
      <Headline>Objetivos</Headline>
      <p>
        Mejorar la situación fisica, psicologica, emocional, social y economica
        de niños y jovenes que padecen cualquier enfermedad cronico degenerativa
        y de sus familias.
      </p>
      <Row>
        <Column className="half">
          <GridImg fluid={data.creamos.childImageSharp.fluid} />
        </Column>
        <Column className="half">
          <h2>Creamos</h2>
          <p>
            Oportunidades de desarrollo para los niños y niñas que tengan alguna
            enfermedad crónico-degenerativa y sus familias, para que la
            enfermedad deje de ser un impedimento para su desarrollo integral.
          </p>
        </Column>
      </Row>
      <Row className="reverse">
        <Column className="half">
          <GridImg fluid={data.cumplimos.childImageSharp.fluid} />
        </Column>
        <Column className="half">
          <h2>Cumplimos</h2>
          <p>
            Los sueños de los niños mediante viajes, actividades y experiencias
            únicas para que las familias puedan salir del ciclo que una
            enfermedad genera en el núcleo familiar, además de proveer terapias
            y talleres.
          </p>
        </Column>
      </Row>
      <Row>
        <Column className="half">
          <GridImg fluid={data.ayudamos.childImageSharp.fluid} />
        </Column>
        <Column className="half">
          <h2>Ayudamos</h2>
          <p>
            a que las familias puedan desarrollarse, potenciando sus habilidades
            mediante talleres, actividades y programas especiales, además de
            ayudar con gastos que la enfermedad genere.
          </p>
        </Column>
      </Row>
    </Container>
  </Layout>
);

Objetivos.propTypes = {
  data: PropTypes.shape({
    creamos: PropTypes.object.isRequired,
    cumplimos: PropTypes.object.isRequired,
    ayudamos: PropTypes.object.isRequired,
  }).isRequired,
};

export default Objetivos;

export const query = graphql`
  query {
    creamos: file(relativePath: { regex: "/creamos.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cumplimos: file(relativePath: { regex: "/cumplimos.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ayudamos: file(relativePath: { regex: "/ayudamos.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const GridImg = styled(Img)`
  height: 300px;
  border-radius: 0.5rem;
`;
